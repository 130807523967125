.ip-profitability-wrapper {
    width: 50%;
    min-width: 400px;
    max-width: 600px;

    .ip-total-col {
        text-align: right;
    }
}


@hack: true; @import "/app/src/asset/theme.less";