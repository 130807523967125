.ip-asset-info-wrapper {
    width: 50%;
    min-width: 400px;
    max-width: 600px;

    .ip-asset-info-box {
        border: 1px solid rgba(5, 5, 5, 0.1);
        border-radius: 6px;
        padding: 8px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .ip-asset-info-total-col {
        text-align: right;
    }
}


@hack: true; @import "/app/src/asset/theme.less";