.ai-p-10 {
    padding: 0;
}

.ai-filter {
    border-radius: 5px;
    border: 1px solid #e0e9ff;
    margin-bottom: 10px;
}

.ai-filter .filter-group-header {
    background: #eef4ff;
    overflow: hidden;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    text-align: center;
    padding: 0 10px;
    font-size: 14px;
    font-family: 'Exo 2';
}

.ai-filter .ai-body {
    margin: 3px 10px;
}

@hack: true; @import "/app/src/asset/theme.less";