.main-layout {
  min-height: 100vh;
}

.main-layout-wrapper {
  margin: 25px 25px;
  flex: 1;

  .main-layout-content {
    margin-left: 20px;
  }
}

@hack: true; @import "/app/src/asset/theme.less";