.ip-transaction-api-loader-wrapper {
    .ant-row {
        margin-bottom: 10px;
    }

    button {
        width: 200px;
    }
}

@hack: true; @import "/app/src/asset/theme.less";