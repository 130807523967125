#Nav3_0 .home-page > .liqfcro7frn-editor_css {
  box-shadow: none;
}
#Pricing1_0 .pricing1-content-wrapper > .ant-row > .lir898t07xe-editor_css {
  margin: 0 0 24px;
}
#Pricing1_0 .ant-row > .ant-col > .lir8eaqyjzu-editor_css {
  width: 320px;
}
#Pricing1_0 .ant-row > .ant-col > .lir8c4zi8q7-editor_css {
  width: 320px;
}
