.main-page {

  &-header {
    max-width: 1200px;
    height: 100%;
    position: relative;
    //margin: auto;
    will-change: transform;
  }

  &-logo {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1.5px;
    width: 200px;
    display: inline-block;
    color: #2a455d;
  }

  &-menu {
    float: right;
    width: 50%;
  }

}

@hack: true; @import "/app/src/asset/theme.less";