
.footer {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}

@hack: true; @import "/app/src/asset/theme.less";