.ip-report-widget {
    //border: 1px solid rgba(5, 5, 5, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    //padding: 10px;
    //margin-right: 10px;
    //margin-bottom: 10px;
    margin-top: 8px;



    .header {
        h4 {
            font-size: 14px;
            font-weight: 500;
            //margin-bottom: 2px;
            color: rgba(0, 0, 0, 0.69);
        }
        .asset-type {
            font-size: 14px;
            font-weight: 400;
            color: rgba(0,0,0,.45);
            margin-bottom: 10px;
        }

    }

    .body {
        display: flex;
        flex-wrap: wrap;
    }
}

@hack: true; @import "/app/src/asset/theme.less";