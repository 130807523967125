@import 'https://fonts.googleapis.com/css?family=Varela';
@import 'https://fonts.googleapis.com/css?family=Exo+2:300,500,600';


body {
  word-wrap: break-word;
  background-color: #fff;

  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.ant-layout-footer {
  background-color: #001529;
  height: 80px;
  overflow: hidden;
}


.ant-menu-light.ant-menu-horizontal {

  .ant-menu-item:hover::after, .ant-menu-item-selected::after, .ant-menu-item-active::after {
    border-bottom-color: transparent;
  }

  .ant-menu-item:hover {
    color: #225f96!important;
  }

  .ant-menu-item-selected {
    font-weight: bold;
    color: #225f96;
    border-bottom-width: 2px;
    border-bottom-color: transparent;
  }
}



@hack: true; @import "/app/src/asset/theme.less";