
.filter-group {
    /*border-bottom: 1px solid rgba(0, 0, 0, 0.42);*/
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;

    .filter-group-header {
        text-align: left;
        background: transparent;
        margin-bottom: 3px;
        color: rgba(0, 0, 0, 0.42);
        /*border-bottom: 1px solid rgba(0, 0, 0, 0.42);*/
    }

    .ant-checkbox-group {
        display: block;
    }

    //> div {
    //    margin-left: 10px;
    //}
}

@hack: true; @import "/app/src/asset/theme.less";