.ip-total-return-table {
    //margin-top: 10px;
    //margin-bottom: 20px;
    font-size: 12px;
    //border: 1px solid #eef4ff;
    width: 100%;

    tr {
        &:nth-of-type(odd) {
            background: #eef4ff;
        }

        th, td {
            padding: 3px;
            border-bottom: 1px solid #eef4ff;
        }

        th {
            background-color: #258bce;
            color: #fff;
        }

        .ip-total {
            text-align: right;
        }
    }
}

@hack: true; @import "/app/src/asset/theme.less";