.ip-detail-table {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 12px;
    border: 1px solid #eef4ff;
    width: 100%;

    tr {
        &:nth-of-type(odd) {
            background: #eef4ff;
        }

        th, td {
            padding: 8px;
            border-bottom: 1px solid #eef4ff;
        }

        th {
            background-color: #258bce;
            color: #fff;
        }

        &.currentPeriod {
            background: rgba(208, 199, 66, 0.13);
            td {
                border-top:2px solid #1e2a3c;
                border-bottom:2px solid #1e2a3c;
            }
        }

    }
}


@hack: true; @import "/app/src/asset/theme.less";