.home-page-wrapper.ant-layout {
  background-color: #ffffff;
}

.home-page {

  &-header {
    max-width: 1200px;
    height: 100%;
    position: relative;
    margin: auto;
    will-change: transform;
  }

  &-logo {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1.5px;
    width: 200px;
    display: inline-block;
    color: #2a455d;
  }

  &-menu {
    float: right;
    width: 50%;
  }

}

.home-page-banner {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fdfdfd;
  background-image: linear-gradient(to top, rgba(253, 253, 253, 0.3) 0%, rgba(193, 218, 255, 0.3) 100%);
  overflow: hidden;

  .banner-wrapper {
    height: 100%;
    max-width: 1200px;
    position: relative;
    margin: auto;
    will-change: transform;

    .banner-title-wrapper {
      width: 40%;
      max-width: 450px;
      position: relative;
      top: 30%;
      left: 24px;
      font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

      .banner-title {
        font-size: 48px;
        line-height: 1.5;
        color: #5f9bf1;
        margin-bottom: 8px;
      }

      .banner-explain {
        color: #333;
        font-size: 24px;
        margin-bottom: 24px;
      }

      .banner-content {
        font-size: 18px;
        color: #666;
        margin-bottom: 48px;
      }

      .banner-button-wrapper {
        background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
        box-shadow: 0 8px 24px rgba(95, 155, 241, 0.35);
        border-radius: 20px;
        border: none;
        padding: 0 42px;
        line-height: 40px;
        height: 40px;
        transition: transform 0.3s, box-shadow 0.3s;
      }
    }
  }

  .banner-img {
    overflow: hidden;
    width: 55%;
    max-width: 710px;
    position: absolute;
    top: 15%;
    right: 24px;
  }
}

.home-page-features {
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 764px;
  padding: 128px 24px;

  &.features-wrapper {
    height: 100%;
    max-width: 1200px;
    position: relative;
    margin: auto;
    will-change: transform;
  }

  .feature-block {
    display: inline-block;
    padding: 48px 24px;
    vertical-align: top;
  }

  .feature-icon{
    display: inline-block;
    width: 15%;
    vertical-align: top;
  }

  .feature-text {
    width: 75%;
    display: inline-block;
    padding-left: 8%;
  }
}

.home-page-pricing {
  height: 100%;
  max-width: 1200px;
  position: relative;
  will-change: transform;
  text-align: center;
  margin: auto;

  .pricing-title-wrapper {
    margin-bottom: 64px;

    h1{
      font-size: 2em;
      font-weight: bold;
    }

    .pricing-title-h1 {

    }
  }

  .pricing-content-wrapper {
    margin: 0 0 24px;

    .pricing-block {

      .pricing-block-box {
        width: 320px;
        background: #eef0f3;
        text-align: center;
        color: #666;
        min-height: 400px;
        margin: auto;
        border-radius: 6px;
        border: 1px solid #e9e9e9;
        transform: translateY(0);
        transition: transform 0.3s linear, box-shadow 0.3s linear;

        .pricing-top-wrapper {
          padding: 16px 24px;

          .pricing-name {
            font-size: 14px;
          }

          h1 {
            font-family: 'Helvetica Neue', sans-serif;
            font-size: 32px;
            color: #666;
          }

        }

        &.active{
          border-color: #1677ff;
          background: #fff;

          .pricing-top-wrapper {
            background: #1677ff;
            color: #fff;
          }

          .pricing-name {
            font-size: 14px;
          }

          h1 {
            color: #fff;
          }
        }



      }


      .pricing-content {
        font-size: 12px;
        line-height: 2;
        font-weight: 300;
        margin: 32px 24px 48px;
      }

      .pricing-line {
        display: block;
        height: 1px;
        background: #d9d9d9;
        margin: 0 24px;
      }

      .pricing-button-wrapper {
        margin: 18px -38px;
      }
    }
  }
}









@hack: true; @import "/app/src/asset/theme.less";