@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/exo2/v24/7cH1v4okm5zmbvwkAx_sfcEuiD8j4PKsOdC6.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/exo2/v24/7cH1v4okm5zmbvwkAx_sfcEuiD8jjPKsOdC6.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/exo2/v24/7cH1v4okm5zmbvwkAx_sfcEuiD8jYPWsOdC6.ttf) format('truetype');
}
