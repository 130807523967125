.asset {
    border: 1px solid rgba(5, 5, 5, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 270px;

    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;

    //align-items: flex-start;

    .asset-header {
        h4 {
            font-size: 16px;
            font-weight: 500;
            //margin-bottom: 2px;
        }
        .asset-type {
            font-size: 14px;
            font-weight: 400;
            color: rgba(0,0,0,.45);
            margin-bottom: 10px;
        }

    }

    .asset-content {
        padding-bottom: 10px;
        margin-bottom: 15px;
        vertical-align: top;
        overflow: hidden;
        //display: block;
        height: 100%;
        .asset-data {
            text-align: right;
        }
    }

    .asset-footer {
        text-align: center;
        color: #999;
        position: relative;
        border-top: 1px solid rgba(5, 5, 5, 0.06);
        padding-top: 10px;

        ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            li {
                float: left;
                list-style: none;
            }

            li:not(:last-child) {
                border-right: 1px solid #f0f0f0;
            }
        }
    }
}

@hack: true; @import "/app/src/asset/theme.less";