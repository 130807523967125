.ip-assetviewer-tools {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 800px;
    //border-bottom: 1px solid #2a9eea;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);

    .ip-active-only {
        display: flex;
        justify-content: flex-end;

        span {
            margin-right: 10px;
        }
    }
}

@hack: true; @import "/app/src/asset/theme.less";