.ip-detail-info-wrapper {
    //width: 100%;
    max-width: 1000px;
    min-width: 400px;

    table {
        width: 100%;

        .action-panel {
            width: 100px;
            display: flex;
            padding: 0;
            margin: 0;
        }
    }
}

@hack: true; @import "/app/src/asset/theme.less";