.ip-bond-detail-header {
    div {
        &.ip-bond-shortname {
            margin-top: 3px;
        }
        &.ip-bond-data {
            margin-top: 10px;
            font-weight: 600;

            span {
                font-weight: normal;
                color: rgba(26, 29, 35, 0.76);
                margin-left: 4px;
            }
        }
    }
}

@hack: true; @import "/app/src/asset/theme.less";