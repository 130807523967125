.ip-page-header {

    font-family: 'Exo 2',BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    color: #2a455d;
    text-transform: none;
    margin-bottom: 15px;

    h1 {
        font-size: 18px;
        line-height: 32px;
        font-weight: 500;
    }

}

@hack: true; @import "/app/src/asset/theme.less";