.ip-assets-allocations-reports {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
}

.ip-report-widget {

    .legend {
        min-width: 400px;
        max-width: 600px;
    }

    .chart {
        width: 500px;
    }

    .total-col {
        text-align: right;
    }

}

@hack: true; @import "/app/src/asset/theme.less";