.ant-table-wrapper {

  .ant-table-container {

    table > thead > tr:first-child {
      >*:first-child {
        border-start-start-radius: 2px;
      }
      >*:last-child {
        border-start-end-radius: 2px;
      }
    }

    .secondary {
      font-size: 11px;
      color: #7b7b7b;
    }

    .secondary.title {
      color: #e0e9ff;
    }

  }
  .ant-table-thead {
    .ant-table-cell {
      background-color: #258bce;
      color: #fff;
      text-align: center;
      font-weight: 500;
    }

    th {
      &.ant-table-column-has-sorters {
        background-color: #258bce;
      }
      &.ant-table-column-has-sorters:hover {
        background-color: rgba(60, 151, 210, 0.93);
      }
    }
  }

}

@hack: true; @import "/app/src/asset/theme.less";